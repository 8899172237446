import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/SN8a97-DeSY">
    <SEO title="The Rest of the Story - Love Songs" />
  </Layout>
)

export default SermonPost
